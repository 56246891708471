<script lang="ts" setup>
const route = useRoute();
const { submissions, isFetching, onSearchTermChanged, isFetchingNextPage, fetchNextPage } =
  useSubmissionFiltering();
</script>

<template>
  <LayoutTemplate>
    <List
      :is-fetching="isFetching"
      :is-fetching-next-page="isFetchingNextPage"
      :on-search-term-changed="onSearchTermChanged"
      :fetch-next-page="fetchNextPage"
      :elements-length="submissions.length"
    >
      <SubmissionListItem
        v-for="claim in submissions"
        v-bind="claim"
        :key="claim.id"
        :route="`./${claim.id}${route.query.search ? `?search=${route.query.search}` : ''}`"
        :origin-url="getFirstDefinedField(claim.origins, 'url')"
        :origin-file="getFirstOriginImage(claim.origins)"
        :active="route.path.includes(claim.id)"
      />
    </List>
  </LayoutTemplate>
</template>
